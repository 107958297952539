import CareCenterStageDisplay from '../images/case_studies/care-center-stage-mockup.png';
import { Colors } from '../theme';

let data = {
  title: 'Care Center Stage',
  services: "Design, Development, Branding",
  date: '2021',
  buttonTitle: "See the work",
  displayImage: CareCenterStageDisplay,
  route: '/work/care-center-stage/',
  color: Colors.Aqua,
  about: 'In early 2021, Tom at CareCenterStage.com came to us with an idea for bringing entertainers together with Care Centers who need it. With just an idea, we helped Tom create a Brand Identity, design an app and interface from the ground up, and build it from scratch.',
  process: [
    {
      title: 'Branding',
      subtitle: 'After doing some research in the field, we worked through a variety of concepts and logo designs.'
    }
  ]

}

export default data;