import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Colors } from '../../theme';

const Wrapper = styled.div`
  max-width: 540px;
  position: relative;
  overflow: visible;
  @media only screen and (max-width: 680px) {
    max-width: 340px;
  
  }
`;

const BorderBackground = styled.div`
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: ${({color}) => color ? '3px solid ' + color : '3px solid ' + Colors.Aqua};
  border-radius: 12px;
  max-width: 472px;  
  width: 100%;
  height: 295px;
  transition: all 300ms ease;
`;

const Image = styled.img`
  max-width: 450px;  
  position: relative;
  transition: all 300ms ease;
  @media only screen and (max-width: 680px) {
    max-width: 340px;
  
  }
  &:hover {
    /* transform: scale(1.1); */
  }
  
`;

const BoxedImage = ({src, color, noBox}) => {
  return (
    <Wrapper>
      {!noBox &&
      <Fragment>
        <BorderBackground className="borderClass" color={color}/>
        <BorderBackground className="borderClass2" color={color}/>
        <BorderBackground className="borderClass3" color={color}/>
        <BorderBackground className="borderClass4" color={color}/>
        <BorderBackground className="borderClass5" color={color}/>
        <BorderBackground className="borderClass6" color={color}/>
        </Fragment>
      }
      <Image alt={src} src={src}/>
    </Wrapper>
  )
}

 export default BoxedImage;
