import React from "react";
// import { Link }         from "gatsby";
import styled from "styled-components";
import Seo from "../../components/seo";
import { Colors } from "../../theme";
import { Header, SubHeader, Body } from "../../components/Typography";
import { SiteContent } from "../../components/Layouts";
import { WorkHero } from "../../components/Heros";
import CCSData from '../../data/care-center-stage'

// Images
// import BakUSAMobile from "../../images/work/bakusa/thumbsupstudio-BakUSA-AtlasPro.jpg";

import "../../theme/css/animations.css";
import data from "../../data/care-center-stage";

const PageWrapper = styled.div`
  position: relative;
  height: 100%;
  background-color: black;
  overflow: hidden;
  min-height: 1200px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: ${Colors.DarkPurple};
  padding-bottom: 128px;
`;

const BodyWrapper = styled.div`
  width: 80%;
`;

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CareCenterStagePage = () => {
  const data = CCSData; //remove this for template
  return (
    <PageWrapper>
      <Seo title="Thumbs Up Studio | Care Center Stage" />
      <WorkHero data={data}/>
      <SiteContent center>
        <RowContainer>
          <Header h4 sofia>About</Header>
          <BodyWrapper>
            <Body maxWidth>{data.about}</Body>
          </BodyWrapper>
        </RowContainer>
      </SiteContent>
    </PageWrapper>
  );
};

export default CareCenterStagePage;
