import React from "react";
import styled from "styled-components";
import { Body, SubHeader, Header } from "../Typography";
import HomeHeroImage from "../../images/home/thumbs-up-studio-bg.jpg";
import { SiteContent } from "../Layouts";
// import Imac from "../../images/home/thumbs-up-studio-imac.png";
// import Experience from "../../images/home/experience.png";
// import { motion, useElementScroll, useViewportScroll, useMotionValue, useTransform } from "framer-motion";
// import useIntersection from '../../components/useIntersection';

const HeroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: url(${HomeHeroImage});
  background-size: cover;
  background-position: bottom;
  padding: 128px 0;
  transform: scale(1);
  /* background-color: white; */
  `;

const ContentWrapper = styled.div`
  padding: 64px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 128px;
  @media only screen and (max-width: 1024px) {
  padding: 24px;

  }
`;

const ExperienceImage = styled.img`
  width: 60%;
  margin-bottom: 36px;

  @media only screen and (max-width: 560px) {
    width: 100%;
  }
`;

const HeaderH1 = styled.h1`
font-family: 'sofia-pro';
max-width: 680px;
  font-size: 54px;
  margin: 0 0 32px 0;
  color: white;

  @media only screen and (max-width: 780px) {
  }

  @media only screen and (max-width: 580px) {
  font-size: 44px;
  }

  @media only screen and (max-width: 400px) {
  }
`;

const HomeHero = () => {
  // const [scrollPosition, setScrollPosition] = useState(0);
  // const { scrollYProgress } = useViewportScroll();
  // const scale = useTransform(scrollYProgress, [0, .4], [1, 2]);
  // const opacity = useTransform(scrollYProgress, [0, .1], [1, 0]);

  // const handleScroll = () => {
  //   const position = window.pageYOffset;
  //   setScrollPosition(position);
  // }

  // useEffect(() => {
  //     window.addEventListener('scroll', handleScroll, { passive: true });

  //     return () => {
  //         window.removeEventListener('scroll', handleScroll);
  //     };
  // }, [scrollPosition]);

  let heroText =
    "Thumbs Up Studio is a small hyper-focused agency designed to bring your digital ideas to life. Website, app, something in-between or something completely custom—we’ve got your back.";
  return (
    <HeroWrapper>
      <SiteContent column flexStart>
        <ContentWrapper>
          <HeaderH1
            // data-aos="fade-in"
            // data-aos-duration={1200}
            // data-aos-delay="400"
          >
            We<span style={{ color: '#8911E1'}}> Design & Develop</span> Modern Web & Mobile
          </HeaderH1>
          <Body
            // data-aos="fade-up"
            // data-aos-duration={1200}
            // data-aos-delay="800"
            // maxWidth={800}
            fontSize={26}
            lineHeight={36}
            align="center"
          >
            {heroText}
          </Body>
        </ContentWrapper>
      </SiteContent>
    </HeroWrapper>
  );
};

export default HomeHero;
